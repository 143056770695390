import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../state/cache/cache.service';

import * as CryptoUtils from '../utils/crypto.utils';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	constructor(private readonly cacheService: CacheService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.cacheService.isPoisonous(req.method, req.url)) {
			console.log('CACHE: Hit poison route. Dumping.');
			this.cacheService.clear();
		}

		if (!this.cacheService.canCache(req.method, req.url)) {
			return next.handle(req);
		}

		return from(this.handle(req, next));
	}

	private async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		let hash: string;
		if (!environment.disableCacheInterceptor) {
			console.log('/////////CACHE-INTERCEPTOR//////////');
			//console.log('CACHE: ', req);
			hash = await CryptoUtils.hashObject(req.body);
			console.log('CACHE: Hash', hash);

			const cacheResult = this.cacheService.get(req.method, req.urlWithParams, hash);
			if (cacheResult) {
				console.log('CACHE: Hit', cacheResult);
				return cacheResult;
			}

			console.log('CACHE: Miss');

			console.log('/////////END-CACHE-INTERCEPTOR//////////');
		}

		return (
			next.handle(req).pipe(
				tap((ev: HttpEvent<any>) => {
					if (ev instanceof HttpResponse && !environment.disableCacheInterceptor) {
						this.cacheService.put(req.method, req.urlWithParams, hash, ev);
					}
				})
			) as Observable<HttpEvent<any>>
		).toPromise();
	}
}
