import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { ExternalIdType, PublicExternalIdType } from '../external-id-type/external-id-type.entity';
import { Invoice } from '../invoice/invoice.entity';
import { Plan } from '../plan/plan.entity';
import { Program } from '../program/program.entity';
import { Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';

export type PublicExternalId = Pick<
	ExternalId,
	'id' | 'externalIdTypeId' | 'planId' | 'programId' | 'tacticId' | 'invoiceId' | 'value' | 'data' | 'created'
> & {
	externalIdType: PublicExternalIdType;
	author: PublicUser;
};

/**
 * Default external id type names.
 */
export enum DefaultExternalIdTypeNames {
	CommercePlatformProjectId = 'Commerce Platform Project Id',
	WppOpenProjectId = 'Wpp Open Project Id',
}

@Entity('externalIds')
export class ExternalId {
	constructor(value?: Partial<ExternalId>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	externalIdTypeId: string;
	@ManyToOne(() => ExternalIdType, {
		eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'externalIdTypeId' })
	externalIdType: ExternalIdType | Partial<ExternalIdType>;

	@Column('text', { nullable: true })
	programId?: string;
	@ManyToOne(() => Program, {
		//eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'programId' })
	program?: Program | Partial<Program>;

	@Column('text', { nullable: true })
	tacticId?: string;
	@ManyToOne(() => Tactic, {
		//eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic | Partial<Tactic>;

	@Column('text', { nullable: true })
	invoiceId?: string;
	@ManyToOne(() => Invoice, {
		//eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'invoiceId' })
	invoice?: Invoice | Partial<Invoice>;

	@Column('text', { nullable: true })
	planId?: string;
	@ManyToOne(() => Plan, {
		//eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'planId' })
	plan?: Plan | Partial<Plan>;

	@Column('text')
	value: string;

	@Column('jsonb', { nullable: true })
	data?: Object;

	@Column('text', { nullable: false })
	authorId: string;

	@ManyToOne((type) => User, {
		eager: true,
		onDelete: 'CASCADE',
	})
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created?: string;

	public toPublic(): PublicExternalId {
		const pub: Partial<PublicExternalId> = {
			id: this.id,
			externalIdTypeId: this.externalIdTypeId,
			planId: this.planId,
			programId: this.programId,
			tacticId: this.tacticId,
			invoiceId: this.invoiceId,
			value: this.value,
			data: this.data,
			created: this.created,
		};

		if (this.externalIdType) {
			pub.externalIdType = new ExternalIdType(this.externalIdType).toPublic();
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		return pub as PublicExternalId;
	}
}
