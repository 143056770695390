import { IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { ApiProperty } from '@nestjs/swagger';

import { SortStrategy } from '../models/find.models';

import { PlanStatus } from '../../plan/plan.entity';
import { InvoiceStatus } from '../../invoice/invoice.entity';

import {
	OrderKey as BudgetCacheOrderKey,
	WhereOptions as BudgetCacheWhereOptions,
} from '../../budget-cache/utils/budget-cache-fragment-generator';
import QueryFragmentGenerator from '../../_core/interfaces/query-fragment-generator.class';
import { TacticSelect } from '../../tactic/utils/query.utils';
import { PlanSelect, PlanSubSelects } from '../../plan/utils/query.utils';
import { ProgramSelect, ProgramSubSelects } from '../../program/utils/query.utils';
import { InvoiceSelect } from '../../invoice/utils/query.utils';
import { FileCategory } from '../../file/file.entity';
import { Property } from '../../_core/decorators/property.decorator';
import { ProgramClassification, ProgramClassificationStatus } from '../../program/program.entity';
import { TacticGroupStatus } from '../../tactic-group/tactic-group.entity';

export class TacticSubSelects implements TacticSubSelects {
	@IsOptional()
	@IsEnum(ProgramSelect, { each: true })
	@ApiProperty()
	programSelects?: ProgramSelect[];
}

export class DateRange {
	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	start?: string;

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	end?: string;
}

export class BudgetRange {
	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	min?: number;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	max?: number;
}

export class RetailerFacets {
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true })
	retailerIds: string[];

	@IsString()
	@IsNotEmpty()
	@ApiProperty()
	budgetPeriodId: string;
}

export class ExternalIdQuery {
	@IsString()
	@IsNotEmpty()
	@ApiProperty()
	externalIdTypeId: string;

	@IsString()
	@IsNotEmpty()
	@ApiProperty()
	query: string;
}

export enum PlanFacetInclude {
	Programs = 'programs',
	Tactics = 'tactics',
}

export enum PlanFacetGrouping {
	Retailers = 'retailers',
	Agencies = 'agencies',
	Brands = 'brands',
	MacroObjectives = 'macroObjectives',
	ProgramSectors = 'programSectors',
	LocationRegion = 'locationRegion',
	LocationArea = 'locationArea',
	LocationCountry = 'locationCountry',
	//...And more?
}

export enum ProgramFacetInclude {
	Tactics = 'tactics',
	Invoices = 'invoices',
	TacticGroups = 'tacticGroups',
}

export enum ProgramFacetGrouping {
	Retailers = 'retailers',
	Agencies = 'agencies',
	Brands = 'brands',
	BrandInitiatives = 'brandInitiatives',
	ProgramSectors = 'programSectors',
	ProgramPhases = 'programPhases',
	Plans = 'plans',
	Tags = 'tags',
	ProgramTypes = 'programTypes',
	BudgetPeriods = 'budgetPeriods',
	MacroObjectives = 'macroObjectives',
	LocationRegion = 'locationRegion',
	LocationArea = 'locationArea',
	LocationCountry = 'locationCountry',
}

export enum TacticFacetInclude {
	Invoices = 'invoices',
}

export enum TacticFacetGrouping {
	Programs = 'programs',
	Retailers = 'retailers',
	Agencies = 'agencies',
	Brands = 'brands',
	TacticTypes = 'tacticTypes',
	TacticCategories = 'tacticCategories',
	TacticPhases = 'tacticPhases',
	Vendors = 'vendors',
	Tags = 'tags',
	MediaType = 'mediaType',
	LocationRegion = 'locationRegion',
	LocationArea = 'locationArea',
	LocationCountry = 'locationCountry',
	TacticGroups = 'tacticGroups',
}

export enum InvoiceFacetGrouping {
	Vendors = 'vendors',
	Statuses = 'statuses',
	Brands = 'brands',
	Programs = 'programs',
	Tactics = 'tactics',
	Retailers = 'retailers',
	Agencies = 'agencies',
}

export class AdvancedSortDescription {
	@ApiProperty({ enum: BudgetCacheOrderKey })
	innerProperty: BudgetCacheOrderKey;
	@ApiProperty({ type: BudgetCacheWhereOptions, required: false })
	options?: BudgetCacheWhereOptions;
}

export class SortDescription {
	@IsNotEmpty()
	@IsString()
	@ApiProperty()
	property: string;

	@IsOptional()
	@IsEnum(SortStrategy)
	@ApiProperty({ enum: SortStrategy })
	strategy: SortStrategy = SortStrategy.ASC;

	@IsOptional()
	@ApiProperty({ type: AdvancedSortDescription, required: false })
	advanced?: AdvancedSortDescription;

	orderString?: string;
	helper?: QueryFragmentGenerator;
	isAdvanced?: boolean;
}

export class SortRequest {
	@IsOptional()
	@ValidateNested()
	@Type(() => SortDescription)
	@ApiProperty({ type: SortDescription, required: false })
	outer?: SortDescription;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortDescription)
	@ApiProperty({ type: SortDescription, required: false })
	level1?: SortDescription;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortDescription)
	@ApiProperty({ type: SortDescription, required: false })
	level2?: SortDescription;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortDescription)
	@ApiProperty({ type: SortDescription, required: false })
	level3?: SortDescription;
}

export class PlanFacets {
	@IsOptional()
	@IsEnum(PlanSelect, { each: true })
	@Property()
	columnSelect?: PlanSelect[];

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty()
	@Property()
	budgetPeriodId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	budgetPeriodIds?: string[];

	organizationId?: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortRequest)
	@ApiProperty({ type: SortRequest, required: false })
	@Property()
	sort?: SortRequest;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	retailerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	agencyIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	locationIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ enum: PlanFacetInclude, isArray: true, required: false })
	@Property()
	include: PlanFacetInclude[] = [];

	@IsOptional()
	@IsString()
	@ApiProperty({ enum: PlanFacetInclude, required: false })
	@Property()
	groupBy?: PlanFacetGrouping;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty({ required: false })
	@Property()
	name?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	brandIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	ownedByMe?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	createdByMe?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	ownerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	authorIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tagIds?: string[];

	@IsOptional()
	@IsEnum(PlanStatus)
	@ApiProperty({ enum: PlanStatus, required: false })
	@Property()
	workflowStatus?: PlanStatus;

	@IsOptional()
	@IsEnum(PlanStatus, { each: true })
	@ApiProperty({ enum: PlanStatus, required: false, isArray: true })
	@Property()
	workflowStatuses?: PlanStatus[];

	@IsOptional()
	@ValidateNested()
	@Type(() => BudgetRange)
	@ApiProperty({ type: BudgetRange, required: false })
	@Property()
	budgetRange?: BudgetRange;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	budgetRangeMin?: number;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	budgetRangeMax?: number;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	macroObjective?: string;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ExternalIdQuery)
	@ApiProperty({ type: ExternalIdQuery, isArray: true, required: false })
	@Property()
	externalIds?: ExternalIdQuery[];

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	programSectorId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programSectorIds?: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	overrideLimit?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programTypeIds?: string[];

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty({ required: false })
	@Property()
	programName?: string;

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	programStart?: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	programAtRisk?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programPhaseIds?: string[];

	// Allows graph functions to populate nested columns.
	subSelects?: PlanSubSelects;

	// Lets the filter pre-processor insert values
	// for the query builders.
	@IsOptional()
	@Property()
	extra?: any;
}

export class ProgramFacets {
	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	ids?: string[];

	@IsOptional()
	@IsEnum(ProgramSelect, { each: true })
	@ApiProperty()
	@Property()
	columnSelect?: ProgramSelect[];

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty()
	@Property()
	budgetPeriodId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	budgetPeriodIds?: string[];

	organizationId?: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortRequest)
	@ApiProperty({ required: false })
	@Property()
	sort?: SortRequest;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	retailerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	agencyIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	locationIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ enum: ProgramFacetInclude, isArray: true })
	@Property()
	include: ProgramFacetInclude[] = [];

	@IsOptional()
	@IsString()
	@ApiProperty({ enum: ProgramFacetGrouping, required: false })
	@Property()
	groupBy?: ProgramFacetGrouping;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty({ required: false })
	@Property()
	name?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	programSectorId?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	planId?: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty()
	@Property()
	includeProgramsFromDraftPlans?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	excludedPlanIds?: string[];

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	programTypeId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	brandInitiativeIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	brandIds?: string[];

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	start?: string;

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	end?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	fundingSourceIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	lazyDate?: boolean;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	programPhaseId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programPhaseIds?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	productIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	ownedByMe?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	createdByMe?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	ownerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	authorIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tagIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	investmentRecap?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	atRisk?: boolean;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	macroObjective?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	kpi?: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasNoTactics?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasMeasurementDashboard?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	needsAttention?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasFiles?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ enum: FileCategory, isArray: true })
	@Property()
	programHasFilesInCategory?: FileCategory[] = [];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ isArray: true })
	@Property()
	programHasPanelsInCategory?: string[] = [];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasNotes?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasPanels?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasObjectives?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => BudgetRange)
	@ApiProperty({ type: BudgetRange, required: false })
	@Property()
	budgetAllocationRange?: BudgetRange;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	budgetAllocationRangeMin?: number;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	budgetAllocationRangeMax?: number;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ExternalIdQuery)
	@ApiProperty({ type: ExternalIdQuery, isArray: true, required: false })
	@Property()
	externalIds?: ExternalIdQuery[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	overrideLimit?: boolean;

	@IsOptional()
	@IsString()
	@ApiProperty({ type: String, required: false })
	@Property()
	/**
	 * @deprecated
	 * @use tacticTypeIds
	 */
	tacticTypeId: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tacticTypeIds: string[];

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	tacticCategoryId?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	tacticPhaseId?: string;

	// Allows graph functions to populate nested columns.
	subSelects?: ProgramSubSelects;

	// Lets the filter pre-processor insert values
	// for the query builders.
	@IsOptional()
	@Property()
	extra?: any;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programSectorIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programTypeIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	vendorIds?: string[];

	@IsOptional()
	@IsEnum(ProgramClassificationStatus)
	@ApiProperty({ enum: ProgramClassificationStatus, required: false })
	@Property()
	classificationStatus?: ProgramClassificationStatus;

	@IsOptional()
	@IsEnum(ProgramClassification)
	@ApiProperty({ enum: ProgramClassification, required: false })
	@Property()
	classification?: ProgramClassification;
}

export class TacticFacets {
	@IsOptional()
	@IsString()
	@ApiProperty()
	@Property()
	budgetPeriodId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	budgetPeriodIds?: string[];

	organizationId?: string;

	@IsOptional()
	@IsEnum(TacticFacetInclude, { each: true })
	@ApiProperty({ enum: TacticFacetInclude, isArray: true, required: false })
	@Property()
	include: TacticFacetInclude[] = [];

	@IsOptional()
	@IsEnum(TacticSelect, { each: true })
	@ApiProperty({ enum: TacticSelect, isArray: true, required: false })
	@Property()
	columnSelect?: TacticSelect[];

	@IsOptional()
	@IsString()
	@ApiProperty({ enum: TacticFacetGrouping, required: false })
	@Property()
	groupBy?: TacticFacetGrouping;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortRequest)
	@ApiProperty({ type: SortRequest, required: false })
	@Property()
	sort?: SortRequest;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty({ required: false })
	@Property()
	name?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tacticTypeIds?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	tacticCategoryId?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	mediaType?: string;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	tacticPhaseId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	fundingSourceIds?: string[];

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	start?: string;

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	end?: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	lazyDate?: boolean;

	@IsOptional()
	@IsDateString()
	@ApiProperty({ required: false })
	@Property()
	lastCouponExpirationDate?: string; //TODO:not used

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	vendorIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	brandIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	retailerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	agencyIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	locationIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tagIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	costTypeIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasPlannedCosts?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => BudgetRange)
	@ApiProperty({ type: BudgetRange, required: false })
	@Property()
	plannedCostRange?: BudgetRange;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	plannedCostRangeMin?: number;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	plannedCostRangeMax?: number;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasInvoices?: boolean; //TODO:not used

	@IsOptional()
	@IsBoolean()
	@Property()
	hasMeasurements?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasFiles?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ enum: FileCategory, isArray: true })
	@Property()
	tacticHasFilesInCategory?: FileCategory[] = [];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	hasNotes?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => BudgetRange)
	@ApiProperty({ type: BudgetRange, required: false })
	@Property()
	actualSpendRange?: BudgetRange;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	actualSpendRangeMin?: number;

	@IsOptional()
	@IsNumber()
	@ApiProperty({ required: false })
	@Property()
	actualSpendRangeMax?: number;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	createdByMe?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	authorIds?: string[];

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ExternalIdQuery)
	@ApiProperty({ type: ExternalIdQuery, isArray: true, required: false })
	@Property()
	externalIds?: ExternalIdQuery[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	overrideLimit?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	isRmn?: boolean;

	// Allows graph functions to populate nested columns.
	@IsOptional()
	@ValidateNested()
	@Type(() => TacticSubSelects)
	@Property()
	subSelects?: TacticSubSelects;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programPhaseIds?: string[];

	@IsOptional()
	@IsEnum(TacticGroupStatus, { each: true })
	@ApiProperty({ enum: TacticGroupStatus, isArray: true, required: false })
	@Property()
	tacticGroupStatuses?: TacticGroupStatus[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tacticGroupIds?: string[];

	// Lets the filter pre-processor insert values
	// for the query builders.
	@IsOptional()
	@Property()
	extra?: any;
}

export class InvoiceFacets {
	@IsOptional()
	@IsEnum(InvoiceSelect, { each: true })
	@Property()
	columnSelect?: InvoiceSelect[];

	@IsOptional()
	@IsString()
	@ApiProperty()
	@Property()
	budgetPeriodId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	budgetPeriodIds?: string[];

	@IsOptional()
	@IsEnum(InvoiceFacetGrouping)
	@ApiProperty({ enum: InvoiceFacetGrouping, required: false })
	@Property()
	groupBy?: InvoiceFacetGrouping;

	@IsOptional()
	@ValidateNested()
	@Type(() => SortRequest)
	@ApiProperty({ type: SortRequest, required: false })
	@Property()
	sort?: SortRequest;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	@ApiProperty({ required: false })
	@Property()
	number?: string;

	@IsOptional()
	@IsEnum(InvoiceStatus)
	@ApiProperty({ enum: InvoiceStatus, required: false })
	@Property()
	status?: InvoiceStatus;

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	vendorId?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	vendorIds?: string[];

	@IsOptional()
	@IsString()
	@ApiProperty({ required: false })
	@Property()
	vendorName?: string;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	vendorNames?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	brandIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	programIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	tacticIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	retailerIds: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	agencyIds?: string[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	pastDue?: boolean;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ExternalIdQuery)
	@ApiProperty({ type: ExternalIdQuery, isArray: true, required: false })
	@Property()
	externalIds?: ExternalIdQuery[];

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	@Property()
	createdByMe?: boolean;

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	@Property()
	authorIds?: string[];

	// Lets the filter pre-processor insert values
	// for the query builders.
	@IsOptional()
	@Property()
	extra?: any;
}

export class FilterSetDto {
	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	retailerIds?: string[];

	@IsOptional()
	@IsString({ each: true })
	@ApiProperty({ type: String, isArray: true, required: false })
	agencyIds?: string[];

	@IsString()
	@ApiProperty()
	@ApiProperty()
	budgetPeriodId: string;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	ownedByMe?: boolean;

	@IsOptional()
	@IsBoolean()
	@ApiProperty({ required: false })
	createdByMe?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => PlanFacets)
	@ApiProperty({ type: PlanFacets, required: false })
	plan?: PlanFacets;

	@IsOptional()
	@ValidateNested()
	@Type(() => ProgramFacets)
	@ApiProperty({ type: ProgramFacets, required: false })
	program?: ProgramFacets;

	@IsOptional()
	@ValidateNested()
	@Type(() => TacticFacets)
	@ApiProperty({ type: TacticFacets, required: false })
	tactic?: TacticFacets;
}
