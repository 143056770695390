import { Plan } from '../../plan/plan.entity';
import { Program } from '../../program/program.entity';
import { Tactic } from '../../tactic/tactic.entity';
import { DataExport as VYCCommercePlatformData } from '../vyc-commerce-platform/models';

export class IntegrationData {
	vycCommercePlatform?: VYCCommercePlatformData;
}

export class IntegrationDataQuery {
	plan?: Partial<Plan>;
	program?: Partial<Program>;
	tactic?: Partial<Tactic>;
}

export enum IntegrationScope {
	All = 'all',
	Native = 'native',
	VYCCommercePlatform = 'vycCommercePlatform',
	WppOpen = 'wppOpen',
}

export class ExtraOptions {
	[key: string]: any;
	scope?: IntegrationScope;
	entityURL?: string;
}
