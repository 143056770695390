import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { AccountBarComponent } from './components/header/account-bar/account-bar.component';
import { NavigationBarComponent } from './components/header/navigation-bar/navigation-bar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GeometricsObjectivesComponent } from './geometrics/geometrics-objectives/geometrics-objectives.component';
import { GeometricsKpisComponent } from './geometrics/geometrics-kpis/geometrics-kpis.component';
import { JoinWithPropPipe } from './pipes/join-with-prop.pipe';
import { A11yModule } from '@angular/cdk/a11y';
import { GeometricsDialogComponent } from './geometrics/geometrics-dialog/geometrics-dialog.component';
import { GeometricsModule } from '@vmlyr/geometrics';
import { OrderModule } from 'ngx-order-pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ButtonSelectComponent } from './components/button-select/button-select.component';
import { AmountDifferenceComponent } from './components/amount-difference/amount-difference.component';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { FillHeightDirective } from './directives/fill-height.directive';
import { DropFileDirective } from './directives/drop-file.directive';
import { SecureRequestPipe } from './pipes/secure-request.pipe';
import { SizeOfPrizeCalculatorDialogComponent } from './size-of-prize-calculator/size-of-prize-calculator-dialog/size-of-prize-calculator-dialog.component';
import { SizeOfPrizeCalculatorModule } from '@vmlyr/size-of-prize-calc';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { MultiLineOptionDirective } from './directives/multi-line-select.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { EntityFieldMaskPipe } from './pipes/entity-field-mask.pipe';
import { PluckFromArrayPipe } from './pipes/pluck-from-array.pipe';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ToggleCheckWithSelectComponent } from './components/toggle-check-with-select/toggle-check-with-select.component';
import { StepBreadcrumbsComponent } from './components/step-breadcrumbs/step-breadcrumbs.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SidebarNavComponent } from './components/sidebar-nav/sidebar-nav.component';
import { LocalVarDirective } from './directives/local-var.directive';
import { MarkDownPipe } from './pipes/markdown.pipe';
import { FilterArrayByCustomPipe } from './pipes/filter-array-by-custom.pipe';
import { HtmlDialogComponent } from './components/html-dialog/html-dialog.component';
import { FilterArrayByPipe } from './pipes/filter-array-by.pipe';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { FindByInArrayPipe } from './pipes/find-by-in-array.pipe';
import { EnumTitlePipe } from './pipes/enum-title.pipe';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SwiperModule } from 'swiper/angular';
import { FilterArrayByMultiplePipe } from './pipes/filter-array-by-multiple.pipe';
import { InitialAvatarComponent } from './components/initial-avatar/initial-avatar.component';
import { MapByCustomPipe } from './pipes/map-by-custom.pipe';
import { MapArrayByPipe } from './pipes/map-array-by.pipe';
import { FlatArrayPipe } from './pipes/flat-array.pipe';
import { materialModules } from '../angular-material.module';
import { FindIndexByInArrayPipe } from './pipes/find-index-by-in-array.pipe';
import { MergeArraysPipe } from './pipes/merge-arrays.pipe';
import { MergeObjectsPipe } from './pipes/merge-objects.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LocationSortPipe } from './pipes/location-sort.pipe';
import { PrettifyTitlePipe } from './pipes/prettify-title.pipe';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { GroupByPipe } from './pipes/group-by.pipe';
import { NumberToCurrencyPipe } from './pipes/number-to-currency.pipe';
import { MaskToNumberPipe } from './pipes/mask-to-number.pipe';
import { LastElementPipe } from './pipes/last-element.pipe';
import { NotAllowedRolesDirective } from './directives/not-allowed-roles.directive';
import { MarkAsViewOnlyIfMissingPermissionsDirective } from './directives/mark-as-view-only-if-missing-permissions.directive';
import { HasPermissionRequirementsDirective } from './directives/has-permission-requirements.directive';
import { HasRolesDirective } from './directives/has-roles.directive';
import { EmptyObjectPipe } from './pipes/empty-object.pipe';
import { TryCatchPipe } from './pipes/try-catch.pipe';
import { IsEmptyObjectPipe } from './pipes/is-empty-object.pipe';
import { FindByInArrayCustomPipe } from './pipes/find-by-in-array-custom.pipe';
import { UserDisplayNamePipe } from './pipes/user-display-name.pipe';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { UniqueArrayByPipe } from './pipes/unique-array-by.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { LoaderToCheckMarkComponent } from './components/loader-to-checkmark/loader-to-checkmark.component';
import { IsDefinedPipe } from './pipes/is-defined.pipe';
import { ExistsInArrayPipe } from './pipes/exists-in-array.pipe';
import { ApplyCustomFunctionPipe } from './pipes/apply-custom-function.pipe';
import { TextSwitcherPipe } from './pipes/text-switcher.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { ResolveDotNotationPathPipe } from './pipes/resolve-dot-notation-path.pipe';
import { GetTableCellValuePipe } from './pipes/get-table-cell-value.pipe';

@NgModule({
	declarations: [
		HeaderComponent,
		AccountBarComponent,
		NavigationBarComponent,
		AutocompleteComponent,
		GeometricsObjectivesComponent,
		GeometricsKpisComponent,
		JoinWithPropPipe,
		GeometricsDialogComponent,
		ConfirmDialogComponent,
		ButtonSelectComponent,
		AmountDifferenceComponent,
		ResizeColumnDirective,
		FillHeightDirective,
		DropFileDirective,
		SecureRequestPipe,
		SizeOfPrizeCalculatorDialogComponent,
		BackToTopComponent,
		MultiLineOptionDirective,
		SafeUrlPipe,
		SafeHtmlPipe,
		EntityFieldMaskPipe,
		PluckFromArrayPipe,
		SelectDialogComponent,
		ShortNumberPipe,
		StepBreadcrumbsComponent,
		ToggleCheckWithSelectComponent,
		AutofocusDirective,
		SidebarNavComponent,
		LocalVarDirective,
		MarkDownPipe,
		FilterArrayByCustomPipe,
		HtmlDialogComponent,
		FilterArrayByPipe,
		FindByInArrayPipe,
		EnumTitlePipe,
		ScrollIntoViewDirective,
		CarouselComponent,
		InitialAvatarComponent,
		MapByCustomPipe,
		FilterArrayByMultiplePipe,
		MapArrayByPipe,
		FlatArrayPipe,
		FindIndexByInArrayPipe,
		MergeArraysPipe,
		MergeObjectsPipe,
		PrettifyTitlePipe,
		LoadingDialogComponent,
		GroupByPipe,
		NumberToCurrencyPipe,
		MaskToNumberPipe,
		LastElementPipe,
		NotAllowedRolesDirective,
		MarkAsViewOnlyIfMissingPermissionsDirective,
		HasPermissionRequirementsDirective,
		HasRolesDirective,
		EmptyObjectPipe,
		TryCatchPipe,
		IsEmptyObjectPipe,
		UserDisplayNamePipe,
		EmptyStateComponent,
		FindByInArrayCustomPipe,
		UniqueArrayByPipe,
		ToDatePipe,
		LoaderToCheckMarkComponent,
		IsDefinedPipe,
		ExistsInArrayPipe,
		ApplyCustomFunctionPipe,
		OrderByPipe,
		LocationSortPipe,
		TextSwitcherPipe,
		ReplacePipe,
		ResolveDotNotationPathPipe,
		GetTableCellValuePipe,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		A11yModule,
		GeometricsModule,
		OrderModule,
		SizeOfPrizeCalculatorModule,
		AngularMultiSelectModule,
		NgxTippyModule,
		SwiperModule,
		...materialModules,
	],
	exports: [
		HeaderComponent,
		AccountBarComponent,
		NavigationBarComponent,
		AutocompleteComponent,
		GeometricsObjectivesComponent,
		GeometricsKpisComponent,
		JoinWithPropPipe,
		ConfirmDialogComponent,
		ButtonSelectComponent,
		AmountDifferenceComponent,
		ResizeColumnDirective,
		FillHeightDirective,
		DropFileDirective,
		SecureRequestPipe,
		BackToTopComponent,
		MultiLineOptionDirective,
		SafeUrlPipe,
		SafeHtmlPipe,
		EntityFieldMaskPipe,
		PluckFromArrayPipe,
		SelectDialogComponent,
		ShortNumberPipe,
		StepBreadcrumbsComponent,
		ToggleCheckWithSelectComponent,
		AutofocusDirective,
		SidebarNavComponent,
		LocalVarDirective,
		MarkDownPipe,
		FilterArrayByCustomPipe,
		HtmlDialogComponent,
		FilterArrayByPipe,
		FindByInArrayPipe,
		EnumTitlePipe,
		ScrollIntoViewDirective,
		CarouselComponent,
		InitialAvatarComponent,
		MapByCustomPipe,
		FilterArrayByMultiplePipe,
		MapArrayByPipe,
		FlatArrayPipe,
		FindIndexByInArrayPipe,
		MergeArraysPipe,
		MergeObjectsPipe,
		PrettifyTitlePipe,
		GroupByPipe,
		NumberToCurrencyPipe,
		MaskToNumberPipe,
		LastElementPipe,
		NotAllowedRolesDirective,
		MarkAsViewOnlyIfMissingPermissionsDirective,
		HasPermissionRequirementsDirective,
		HasRolesDirective,
		EmptyObjectPipe,
		TryCatchPipe,
		IsEmptyObjectPipe,
		EmptyStateComponent,
		FindByInArrayCustomPipe,
		UserDisplayNamePipe,
		UniqueArrayByPipe,
		ToDatePipe,
		LoaderToCheckMarkComponent,
		IsDefinedPipe,
		ExistsInArrayPipe,
		ApplyCustomFunctionPipe,
		OrderByPipe,
		LocationSortPipe,
		TextSwitcherPipe,
		ReplacePipe,
		ResolveDotNotationPathPipe,
		GetTableCellValuePipe,
	],
	providers: [TitleCasePipe],
})
export class SharedModule {}
