import { Component, HostBinding, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

import { HeaderSettings } from './state/global/global.model';
import { GlobalService } from './state/global/global.service';
import { SessionQuery } from './state/session/session.query';
import { SessionService } from './state/session/session.service';
import { GlobalQuery } from './state/global/global.query';
import { fade } from './_core/utils/animations.utils';

import { environment } from '../environments/environment';

import { SelectDialogComponent } from './shared/components/select-dialog/select-dialog.component';
import { ORG_SETTINGS } from './state/session/session.store';
import { FilterService } from './state/entities/filter/filter.service';
import { DocumentationService } from './documentation/services/documentation.service';
import { DocumentationFlowsListComponent } from './documentation/components/dev/flows-list/flows-list.component';
import { __stores__ } from '@datorama/akita';
import { DocumentationQaListComponent } from './documentation/components/qa/qa-list/qa-list.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fade('fade', 500)],
})
export class AppComponent implements OnInit {
	@HostBinding('class.no-sidebar') hideSidebar = false;

	readonly UNSECURED_ROUTES = ['/login', '/wpp-open'];

	public loaded = false;
	public headerSettings$: Observable<HeaderSettings>;
	public showSidebar = false;
	public showDocumentationButton = false;

	constructor(
		private readonly globalQuery: GlobalQuery,
		private readonly globalService: GlobalService,
		private readonly sessionService: SessionService,
		private readonly sessionQuery: SessionQuery,
		private readonly filterService: FilterService,
		private readonly router: Router,
		private readonly location: Location,
		private readonly dialog: MatDialog,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly renderer: Renderer2,
		public readonly documentationService: DocumentationService
	) {
		this.headerSettings$ = this.globalQuery.select('header');
		this.initializeApp().catch((err) => {
			console.log(err);
		});
	}

	ngOnInit(): void {
		// Log the state in development
		if (!environment.production) {
			console.log('%cState', 'color: lightgreen', __stores__);
		}

		const hostname = window?.location?.hostname;
		this.showDocumentationButton = hostname && (hostname === 'localhost' || hostname === 'demo.simplcx.com');
	}

	// Detect keypresses for setting admin mode
	@HostListener('document:keydown', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		if (event.key === ';' && event.ctrlKey) {
			this.globalService.setAdminMode();
		}
	}

	loadGlobalSettings() {
		this.globalService.get().subscribe(
			async (settings) => {
				console.log('Loaded Global Settings', settings);
				Sentry.setTag('organizationName', settings.name);

				// Set the favicon
				const favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;
				favIcon.href = settings.settings.favIcon || 'assets/favicon/vmlyr.ico';

				// Set custom styles
				// this.customStyles = settings.customStyles;
				if (settings.settings.customStyles) {
					const style = document.createElement('style');
					style.textContent = settings.settings.customStyles;
					document.head.appendChild(style);
				}

				// Load filter options in before we start setting up the views
				this.filterService.setFilterOptionsFromSettings(settings);

				this.loaded = true;
				// Save default frontend URL
				if (!settings?.settings?.frontendUrl) {
					await this.globalService.updateOrganizationSettings({ frontendUrl: this._extractBaseUrl() }).toPromise();
				}
			},
			(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
		);
	}

	openDocumentationFlows(): void {
		this.dialog.open(DocumentationFlowsListComponent, {
			data: null,
			disableClose: false,
			panelClass: ['fullscreen', 'background-color', 'max-width-lg'],
		});
	}

	openDocumentationQA(): void {
		this.dialog.open(DocumentationQaListComponent, {
			data: null,
			disableClose: false,
			panelClass: ['fullscreen', 'background-color', 'max-width-lg'],
		});
	}

	private async initializeApp() {
		const settings = await this.loadOrgSettings().catch((err) => {
			console.log(err);
			localStorage.removeItem(ORG_SETTINGS);
			return null;
		});

		if (!settings) {
			console.error('Configuration error. Unable to load or parse API_MAP.');
		}
		if (this.UNSECURED_ROUTES.some((route) => this.location.path().toLowerCase().startsWith(route.toLowerCase()))) {
			this.hideSidebar = true;
		}

		// Once logged in, load settings and start up services
		this.sessionQuery.isLoggedIn$.pipe(filter((isLoggedIn) => isLoggedIn === true)).subscribe(() => {
			console.log('App initialization', settings);

			this.loadGlobalSettings();
		});

		// Refresh our token before we do anything else
		if (!this.UNSECURED_ROUTES.some((route) => this.location.path().toLowerCase().startsWith(route.toLowerCase()))) {
			this.sessionService.getUserStatus(this.sessionQuery.getToken()).subscribe(
				() => {},
				() => {
					// Save the location path so we can go back to it
					this.sessionService.setInitialUrl(this.location.path());

					// Invalid Access Token
					this.router.navigate(['login'], {
						replaceUrl: true,
						skipLocationChange: true,
					});
					this.loaded = true;
				}
			);
		} else {
			this.loaded = true;
		}

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
			// Hide sidebar on unsecured routes
			this.showSidebar = !this.UNSECURED_ROUTES.some((route) => val.url.toLowerCase().startsWith(route.toLowerCase()));
			if (!this.showSidebar) {
				// Change the left margin of the details page to 0px
				if (window.document.getElementsByClassName('details-page')?.item(0)) {
					this.renderer?.setStyle(window.document.getElementsByClassName('details-page')?.item(0), 'left', '0px');
				}
			}
		});
	}

	private async loadOrgSettings() {
		return new Promise((resolve, reject) => {
			if (!environment.exclusive) {
				const savedOrg = localStorage.getItem(ORG_SETTINGS);

				if (savedOrg) {
					try {
						const orgData = JSON.parse(savedOrg);
						environment.apiUrl = orgData.endpoint;
						environment.organizationId = orgData.organizationId;
						environment.calendarLicense = orgData.calendarLicense || environment.calendarLicense;
						environment.production = orgData.production || environment.production;
						environment.disableActivation = orgData.disableActivation || environment.disableActivation;
						environment.disablePlanning = orgData.disablePlanning || environment.disablePlanning;
						environment.currencySymbol = orgData.currencySymbol || environment.currencySymbol;
						environment.currencyCode = orgData.currencyCode || environment.currencyCode;
						environment.locale = orgData.locale || environment.locale;
						resolve(true);
					} catch (err) {
						reject(`Couldn't parse org settings.`);
						return;
					}
				} else {
					const dialogRef = this.dialog.open(SelectDialogComponent, {
						data: {
							title: 'Select an Organization',
							options: environment.apiSettings?.reduce((acc, cur) => {
								acc[cur.name] = cur;
								return acc;
							}, {}),
						},
					});

					dialogRef.afterClosed().subscribe((result) => {
						localStorage.setItem(ORG_SETTINGS, JSON.stringify(result));
						environment.apiUrl = result.endpoint;
						environment.organizationId = result.organizationId;
						environment.calendarLicense = result.calendarLicense || environment.calendarLicense;
						environment.production = result.production || environment.production;
						environment.disableActivation = result.disableActivation || environment.disableActivation;
						environment.disablePlanning = result.disablePlanning || environment.disablePlanning;
						environment.currencySymbol = result.currencySymbol || environment.currencySymbol;
						environment.currencyCode = result.currencyCode || environment.currencyCode;
						environment.locale = result.locale || environment.locale;
						resolve(true);
					});
				}
			} else {
				resolve(true);
			}
		});
	}

	private _extractBaseUrl(): string {
		const protocol = this.document.location.protocol;
		const hostname = this.document.location.hostname;
		const port = this.document.location.port;

		return `${protocol}//${hostname}${port ? ':' + port : ''}/`;
	}
}
